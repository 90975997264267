/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../components/Button";
import { ERC721TokenType, ImmutableXClient, Link } from "@imtbl/imx-sdk";
import { apiAddress, environment, linkAddress, Toast } from "../utils/Global";
import defaultImage from "../assets/img/signUp.jpg";
import { Context } from "../context/WalletContext";
import Popup from "../components/Popup";
import InputField from "../components/InputField";

const Transfers = () => {
  const [assets, setAssets] = useState([]);
  const link = new Link(linkAddress);
  const { immutableLogin } = useContext(Context);
  const [selected, setSelected] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [input, setInput] = useState("");
  const [showInput, setShowInput] = useState(false);
  const addressLocalStorage = localStorage.getItem("WALLET_ADDRESS");
  useEffect(() => {
    getHistory();
  }, []);
  const get = async () => {
    const client = await ImmutableXClient.build({ publicApiUrl: apiAddress });
    const assetsRequest = await client.getAssets({
      user: addressLocalStorage,
      collection:
        environment === "dev"
          ? "0x988f1fa14cd51d726e76eb44e5c169e71e214127"
          : "0xeea2fe42cc1774a802edfc7a08a35c361827befc",
    });
    if (assetsRequest.result.length > 0) {
      setAssets(assetsRequest.result);
    }
  };
  const getHistory = async () => {
    if (addressLocalStorage !== null) {
      get();
    } else {
      await immutableLogin();
      get();
    }
  };
  const verify = async () => {
    setShowPopup(false);
    const array = [];
    const nfts = [];
    selected.forEach((element) => {
      array.push(assets[element].token_id || assets[element].tokenId);
      nfts.push({
        type: ERC721TokenType.ERC721, // Must be of type ERC721
        tokenId: assets[element].token_id || assets[element].tokenId, // the token ID
        tokenAddress: assets[element].token_address, // the collection address / contract address this token belongs to
        toAddress: input, // the wallet address this token is being transferred to
      });
    });
    // transfer token to wallet
    let response = await link.batchNftTransfer(nfts);
    if (response.result.length > 0) {
      setShowInput(false);
      getHistory();
      Toast.set({
        show: true,
        desc: "Your transfer is successful",
        type: "success",
      });
    } else {
      Toast.set({
        show: true,
        desc: "Something went wrong. Please contact us at info@qorgaming.com ",
        type: "error",
      });
    }
  };
  return (
    <div className="darkbg pt-5 assets">
      {showPopup ? (
        <Popup
          onClose={() => setShowPopup(false)}
          title="Confirmation required"
          content={
            <>
              <p>Are you sure you want to transfer your asset?</p>
              <Button
                label="Yes, Please"
                onClick={() => {
                  setShowPopup(false);
                  setShowInput(true);
                }}
              />
              <span style={{ width: 10, display: "inline-block" }} />
              <Button
                transparent
                label="No"
                onClick={() => setShowPopup(false)}
              />
            </>
          }
        />
      ) : null}
      {showInput ? (
        <Popup
          onClose={() => setShowInput(false)}
          title="Add Wallet"
          content={
            <>
              <InputField
                onChange={(text) => setInput(text)}
                placeholder="Wallet Address"
              />
              <Button
                label="Submit"
                onClick={() =>
                  input !== ""
                    ? verify()
                    : Toast.set({
                      show: true,
                      type: "error",
                      desc: "Wallet address can not be empty",
                    })
                }
              />
              <span style={{ width: 10, display: "inline-block" }} />
              <Button
                transparent
                label="Cancel"
                onClick={() => setShowInput(false)}
              />
            </>
          }
        />
      ) : null}
      {selected.length > 0 ? (
        <button className="floating-btn" onClick={() => setShowPopup(true)}>
          Transfer Now
        </button>
      ) : null}
      <div className="section">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-4">
              <div className="left-side">
                <h2>Qorbi Earned</h2>
                <div className="item">
                  <div>
                    <span className="date">11/24/2022</span>
                    <br />
                    <span className="name">Baby #87</span>
                  </div>
                  <div className="price">4.56 QORBI</div>
                </div>
                <div className="item">
                  <div>
                    <span className="date">11/24/2022</span>
                    <br />
                    <span className="name">Baby #87</span>
                  </div>
                  <div className="price">4.56 QORBI</div>
                </div>
              </div>
            </div> */}
            <div className="col-md-12 right-side">
              <h2>Transfer Your Assets</h2>
              <div className="row">
                {assets.length > 0 ? (
                  assets.map((item, index) => (
                    <div
                      className="col-md-4 mb-3"
                      key={index}
                      onClick={() => {
                        // check if egg is already selected
                        if (selected.includes(index)) {
                          // then remove from the array
                          const indx = selected.indexOf(index);
                          const clone = [...selected];
                          clone.splice(indx, 1);
                          setSelected(clone);
                        } else {
                          // then add to the array
                          setSelected([...selected, index]);
                        }
                      }}
                    >
                      <div
                        className="bg"
                        style={{
                          backgroundImage: `url(${item.image_url != null
                              ? item.image_url
                              : defaultImage
                            })`,
                        }}
                      >
                        {selected.includes(index) ? (
                          <div className="check">
                            <span className="fa fa-check-square" />
                          </div>
                        ) : null}
                      </div>
                      <div className="cont">
                        <div className="logo">
                          <img alt="logo" src="/assets/img/Green_Isotype.png" />
                        </div>
                        <h4>{item.name}</h4>
                        {/* <Button
                        label="View Details"
                      /> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <h2 className="text-center">
                    There are no assets for Qorbi World in your wallet
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfers;
