import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import { Context } from "../context/WalletContext";
import { Sphereone } from "../utils/Global";
import { sphereoneSdk } from "../utils/SphereOne";

const RegisterForm = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const walletAddress = localStorage.getItem("WALLET_ADDRESS")
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { logOut, userLogout, state } = useContext(Context);
    const { setRegisterUser, sphereOneWallets, sphereOneUser, setSphereOneUser } = useSphereOneProvider();

    const handleChangeUsername = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setUsername(value);
        }
    };

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setEmail(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state?.user?.authType === Sphereone) {
            try {
                const response = await axios.post('https://api.qorbiworld.com/sphere-one/add-user', {
                    username: username,
                    walletId: sphereOneWallets.filter(wallet => !wallet.isImported)[0].address,
                    email: sphereOneUser.email,
                    sphereOneId: sphereOneUser.uid,
                });
                console.log(response);
                setSphereOneUser({ ...sphereOneUser, username: username });
                setRegisterUser(false)
            } catch (addUserError) {
                console.error('Error adding user to Wombat:', addUserError);
                setErrorMessage(addUserError.response.data.message);
            }
        } else {
            try {
                const response = await axios.post('https://api.qorbiworld.com/sphere-one/add-user', {
                    username: username,
                    walletId: walletAddress,
                    email: email,
                });
                console.log(response);
                setRegisterUser(false)
            } catch (addUserError) {
                console.error('Error adding user to Wombat:', addUserError);
                setErrorMessage(addUserError.response.data.message);
            }
        }
    };

    const logoutSphereOne = async () => {
        localStorage.removeItem("userInfo")
        if (localStorage.getItem("WALLET_ADDRESS")) {
            localStorage.removeItem("STARK_PUBLIC_KEY");
            localStorage.removeItem("WALLET_ADDRESS");
        }
        try {
            sphereoneSdk.logout();
        } catch (e) {
            console.error(e);
        }
        window.location.reload()
    };

    return (
        <div className="main-overlay">
            <div className='black-overlay' style={{ width: !isMobile && "30%" }}>
                <h2 style={{ color: "white" }}>Create Your Account</h2>
                {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}
                <label htmlFor="username" style={{ color: "white", marginBottom: 15 }}>This is your gamer username.</label>
                <form onSubmit={handleSubmit}>
                    <div className="register-form-group">
                        <label htmlFor="username" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Username:</label>
                        <input
                            style={{ backgroundColor: "white" }}
                            className='register-form-input'
                            type="text"
                            id="username"
                            placeholder="Ex: CoolQorUser"
                            value={username}
                            onChange={handleChangeUsername}
                            required
                        />
                    </div>
                    {state?.user?.authType !== Sphereone &&
                        <div className="register-form-group">
                            <label htmlFor="email" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Email:</label>
                            <input
                                className='register-form-input'
                                type="email"
                                id="email"
                                placeholder="Ex: cool@qor.user"
                                value={email}
                                onChange={handleChangeEmail}
                                required
                            />
                        </div>}
                    <button className='register-form-button' type="submit">Register</button>
                </form>
                <button
                    style={{ marginTop: 15, backgroundColor: "red", transition: "background-color 0.3s" }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = "#B80000"}
                    onMouseLeave={(e) => e.target.style.backgroundColor = "red"}
                    className='register-form-button'
                    onClick={() => {
                        logOut();
                        userLogout();
                        logoutSphereOne();
                    }}>
                    Cancel Login
                </button>
            </div>
        </div>
    );
};

export default RegisterForm;
