/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Context } from "../context/WalletContext";

const ThankYouEggdrop = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { AddCodeMint, getEggImage } = useContext(Context);
  const [image, setImage] = useState("/assets/img/Jaguar_Silver.gif");
  const submitCode = async () => {
    let res = await getEggImage();
    if (res.success) {
      setImage(res.data.imageUrl);
    }
    const params = new URLSearchParams(search);
    if (params.get("code") !== "") {
      await AddCodeMint(params.get("code"));
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    submitCode();
  }, []);
  return (
    <div className="darkbg">
      <div className="section plan-section" style={{ paddingTop: 80 }}>
        <div className="container">
          <div className="row single-baby">
            <div className="col-12">
              <div className="col-md-12 text-center">
                <img src={image} alt="thankyou" style={{ height: 200 }} />
                <h4 className="attribute-title" style={{ fontSize: 24 }}>
                  We just sent you an email with <br /> instructions. <br />
                  <small style={{ fontSize: 12, fontFamily: "sf-regular" }}>
                    If you do not receive the email contact us on Twitter{" "}
                    <a
                      href="https://twitter.com/QorbiWorld"
                      rel="noreferrer"
                      target="_blank"
                      style={{ color: "#51e180" }}
                    >
                      @QorbiWorld
                    </a>
                  </small>
                </h4>
                <a className="btnTransparent" href="/mint">
                  <span>Mint Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouEggdrop;
