import React, { Component } from 'react';
import { Banner } from '../components/Banner';
import { Button } from '../components/Button';
class Stake extends Component {
    render() {
        return (
            <div className='darkbg'>
               <Banner 
                    imgURL='./assets/img/homebg.jpg' 
                    title={<>Stake your Baby or your Qorbi</>} 
                    subtitle={<>COMING SOON. Stake your Qor Baby NFT and/or your QORBI. Connect your account and stake today.</>}
                    buttons={<div className='button-container'>
                        <Button type='button' label="Coming soon" transparent={true}/>
                    </div>}
                />
            </div>
        );
    }
}

export default Stake;