import React, { useState } from "react";
import detectEthereumProvider from '@metamask/detect-provider'
import { linkAddress } from "../utils/Global";
import { Link } from "@imtbl/imx-sdk";

const WalletTesting = () => {
    const [wallet, setWallet] = useState({})
    const [isWobat, setIsWobat] = useState(window.ethereum.isWombat)

    const connectMetamask1 = async () => {
        try {
            const providerMetamask = await detectEthereumProvider();
            if (providerMetamask) {
                const accounts = await providerMetamask.request({
                    method: "eth_requestAccounts",
                })

                const address = accounts[0];
                const chainId = parseInt(await providerMetamask.request({ method: 'eth_chainId' }));
                const balance = (parseInt(await providerMetamask.request({
                    method: "eth_getBalance",
                    params: [address, "latest"],
                })) / 1000000000000000000).toFixed(2);
                setWallet({ address, chainId, balance })
                setIsWobat(window.ethereum.isWombat)
            }
        } catch (error) {
            console.error("Error connecting to MetaMask:", error);
        }
    };

    const connectMetamask2 = async () => {
        try {
            const providerMetamask = await window.ethereum;
            if (providerMetamask) {
                const accounts = await providerMetamask.request({
                    method: "eth_requestAccounts",
                })

                const address = accounts[0];
                const chainId = parseInt(await providerMetamask.request({ method: 'eth_chainId' }));
                const balance = (parseInt(await providerMetamask.request({
                    method: "eth_getBalance",
                    params: [address, "latest"],
                })) / 1000000000000000000).toFixed(2);

                setWallet({ address, chainId, balance })
                setIsWobat(window.ethereum.isWombat)
            }
        } catch (error) {
            console.error("Error connecting to MetaMask:", error);
        }
    };

    const connectIMX = async () => {
        const link = new Link(linkAddress);
        const ImxWalletData = await link.setup({
            providerPreference: "none",
        });
        setWallet(ImxWalletData)
        setIsWobat(window.ethereum.isWombat)
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 150
        }}>
            <button
                style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    padding: "15px 32px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "5px",
                }}
                onClick={connectMetamask1}
            >
                Connect Metamask 1
            </button>
            <button
                style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    padding: "15px 32px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "5px",
                }}
                onClick={connectMetamask2}
            >
                Connect Metamask 2
            </button>
            <button
                style={{
                    backgroundColor: "#008CBA",
                    border: "none",
                    color: "white",
                    padding: "15px 32px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "5px",
                }}
                onClick={connectIMX}
            >
                Connect IMX
            </button>
            <div style={{ color: "white" }}>
                {Object.entries(wallet).map(([key, value]) => (
                    <div key={key} style={{
                        margin: "5px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                    }}>
                        <strong>{key}:</strong> {value}
                    </div>
                ))}
            </div>
            <div style={{ marginTop: "20px" }}>
                {isWobat ? <span style={{ color: "green" }}>Wombat Wallet</span> : <span style={{ color: "red" }}>Not Wombat Wallet</span>}
            </div>
        </div>
    );
};

export default WalletTesting;
