import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../context/WalletContext";

export const MenuNoLogin = () => {
  let location = useLocation();
  const { state } = useContext(Context);
  let menu = [
    { name: "Home", path: "/", type: "internal" },
    { name: "Games", path: "/games", type: "internal" },
    {
      name: "White Paper",
      path: "https://whitepaper.qorbiworld.com/",
      type: "external",
    },
    { name: "Shop", path: "/market", type: "internal" },
    { name: "Mint", path: "/mint", type: "internal" },
  //  { name: "$Qorbi", path: "/qorbi", type: "internal" },
    { name: "My Assets", path: "/my-assets", type: "internal" },
    // { name: "Transfers", path: "/transfers", type: "internal" },
  ];
  return (
    <>
      {menu.map((item, index) => (
        <li className="nav-item dropdown" key={index}>
          {item.type === "external" ? (
            <a
              href={item.path}
              target="_blank"
              className={`nav-link ${
                location.pathname === item.path ? "active" : null
              }`} rel="noreferrer"
            >
              {item.name}
            </a>
          ) : (
            <Link
              className={`nav-link ${
                location.pathname === item.path ? "active" : null
              }`}
              to={item.path}
            >
              {item.name}
              {item.subpages ? <i className="fas fa-angle-down ml-1" /> : null}
            </Link>
          )}
          {item.subpages ? (
            <ul className="dropdown-menu">
              {item.subpages.map((item2, index2) => (
                <li className="nav-item" key={index2}>
                  {item2.type === "external" ? (
                    <a
                      href={item2.path}
                      target="_blank"
                      className={`nav-link ${
                        location.pathname === item.path ? "active" : null
                      }`} rel="noreferrer"
                    >
                      {item2.name}
                    </a>
                  ) : (
                    <Link
                      to={item2.path}
                      className={`nav-link ${
                        location.pathname === item2.path ? "active" : null
                      }`}
                    >
                      {item2.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          ) : null}
        </li>
      ))}
      {state.marketplaceStatus && state.marketplaceStatus.mintEnable ? (
        <li className="nav-item dropdown">
          <Link
            className={`nav-link ${
              location.pathname === "/mint" ? "active" : null
            }`}
            to="mint"
          >
            Mint
          </Link>
        </li>
      ) : null}
    </>
  );
};
