import React, { Component } from 'react';
import { Banner } from '../components/Banner';
const babies=["https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/Eighth.mp4","https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/Fifth.mp4","https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/First.mp4","https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/Fourth.mp4","https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/Second.mp4","https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/Seventh.mp4","https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/Sixth.mp4","https://nft.qorbaby.com/ipfs/QmYfnxXEgBrqxL7KMy2Bm9oaBhNqgeVuXwRwyTybFhAL96/Third.mp4"]
class Babies extends Component {
    componentDidMount(){
        const script= document.createElement("script")
        script.src="//embed.typeform.com/next/embed.js"
        script.async = true
        document.body.appendChild(script)
    }
    render() {
        return (
            <div>
                <Banner imgURL="./assets/img/homebg.png" title="Sign up for early access to the Qor Baby"/>
                <div className='container'>
                    <div className='row section'>
                        <div className='col-12'>
                            <div data-tf-widget="R3AVWBS1" data-tf-iframe-props="title=Qor Baby Pre Order" style={{width:'100%',height:500}}></div>
                        </div>
                    </div>
                    <div className='row section'>
                        <div className='col-12'>
                            <h2 className='green-title'>Be one of the first to be a Qor Baby Owner</h2>
                        </div>
                        {
                            babies.map((item,index)=>(
                                <div className='col-12 col-md-6 col-lg-3 baby-video' key={index}>
                                    <video autoPlay muted loop>
                                        <source src={item} type="video/mp4"/>
                                    </video>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Babies;