import React, { useContext, useState } from "react";
import { Button } from "../components/Button";
import close from "../assets/img/close.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Context } from "../context/WalletContext";
import { Toast } from "../utils/Global";

const ForgotPassword = () => {
  const [fields, setFields] = useState({
    email: "",
  });
  const { forgotPassword } = useContext(Context);
  const navigate = useNavigate();
  const recovery = async () => {
    if (fields.email !== "") {
      let res = await forgotPassword(fields.email);
      if (res.success) {
        Toast.set({
          show: true,
          type: "success",
          desc: "We sent you an email to reset your password. Please follow the email instructions",
        });
        navigate("/");
      }
    } else {
      Toast.set({ show: true, title: "Error", desc: "Fields Required!" });
    }
  };
  return (
    <div className="signin-bg">
      <div className="content">
        <div className="box">
          <h2>
            Account <br />
            Recovery!
            <img
              onClick={() => navigate("/")}
              src={close}
              alt="close"
              style={{ width: 20, height: 20, cursor: "pointer" }}
            />
          </h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              recovery();
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="field">
                  <input
                    onChange={({ target }) =>
                      setFields({ ...fields, email: target.value })
                    }
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button label="Submit" />
              </div>
              <div className="col-12 social-header">
                <hr className="line" />{" "}
                <span>Or Sign In with other account</span>
                <hr className="line" />
              </div>
              <div className="col-12 text-center">
                Don't have an account?
                <Link
                  to="/signup"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
