import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Context } from "../context/WalletContext";
import { PopUp, Toast } from "../utils/Global";
import { amountToLamports } from "../utils/toLamport";

const SingleBaby = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const {
    state,
    getBaby,
    makeTransaction,
    buyNFT,
    shoppingCart,
    removeshoppingCart,
    sendEmail,
    checkWallet,
    LoginWallet,
    convertToUSD,
  } = useContext(Context);
  const [data, setData] = useState(null);
  const [showAssets, setShowAsset] = useState("image");
  const [showCard, setShowCard] = useState(false);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    getSingleBaby();
    if (!state.userWallet) {
      connectWallet();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUSDConvertion = async () => {
    let res = await convertToUSD(data.price);
    if (res.success) {
      setPrice(res.data.value);
      setTimeout(() => {
        setShowCard(true);
      }, 1000);
    }
  };
  const connectWallet = async () => {
    let res = await checkWallet();
    if (res) {
      PopUp.set({
        show: true,
        title: "Choose your prefer wallet",
        content: (
          <div className="wallet-options">
            <button
              className="greenBtn btnOpt"
              onClick={async () => {
                await LoginWallet("solflare");
                PopUp.set({ show: false, title: "", content: null });
              }}
            >
              <img src="/assets/img/solflare-icon.png" alt="phantom" /> Solflare
              Wallet
            </button>
            <button
              className="btnTransparent btnOpt"
              onClick={async () => {
                await LoginWallet("phantom");
                PopUp.set({ show: false, title: "", content: null });
              }}
            >
              <img src="/assets/img/phantom-icon.png" alt="phantom" /> Phantom
              Wallet
            </button>
          </div>
        ),
      });
    } else {
      LoginWallet();
    }
  };
  const getSingleBaby = async () => {
    let res = await getBaby(id);
    if (res.success && !res.data.message) {
      setData(res.data);
    } else {
      navigate("/marketplace");
    }
  };
  const addShoppinCart = async () => {
    let res = await shoppingCart(data._id);
    console.log("shopping", res);
    if (res.success) {
      if (res.data.status) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const checkIfNotSold = async () => {
    let res = await getBaby(id);
    console.log("aqui", res);
    if (res.success) {
      if (res.data.status === "OnSalle") {
        return true;
      } else {
        setData(res.data);
        return false;
      }
    }
  };
  const buy = async () => {
    if (state.userWallet) {
      if (await checkIfNotSold()) {
        let shopping = await addShoppinCart();
        console.log("shop", shopping);
        if (shopping) {
          let price = await amountToLamports(data.price.toFixed(2), "sol");
          console.log(price);
          let res = await makeTransaction(price, state.userWallet.name);
          console.log("trans", res);
          if (res.success) {
            let nft = await buyNFT(state.userWallet.string, data._id, res.data);
            console.log("nft", nft);
            if (nft.success) {
              Toast.set({
                type: "success",
                desc: "Thank you, You are now the proud owner of a Qorb.",
                show: true,
              });
              navigate("/thankyou");
            } else {
              let message = {
                from: state.userWallet.string,
                nftId: data._id,
                message: "something went wrong buying the NFT",
              };
              await removeshoppingCart();
              await sendEmail(JSON.stringify(message));
            }
          } else {
            console.log("enter here");
            await removeshoppingCart();
          }
        } else {
          await removeshoppingCart();
        }
      } else {
        Toast.set({
          type: "error",
          desc: "This NFT is already been purchased.",
          show: true,
        });
      }
    } else {
      connectWallet();
    }
  };
  return (
    <div className="darkgreen-bg single-baby">
      {data && data !== null ? (
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-7">
                <div className="row media-container">
                  <div className="col-md-12 col-lg-2 thumbnail-cont">
                    <img
                      alt="thumbnail"
                      src={data.ImageUrl}
                      className="thumbnail"
                      onClick={() => setShowAsset("image")}
                    />
                    <div
                      className="video-image"
                      onClick={() => setShowAsset("video")}
                    >
                      <img
                        alt="thumbnail"
                        src={data.ImageUrl}
                        className="thumbnail"
                      />
                      <div className="overflow">
                        <i className="fa fa-play" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-10">
                    <div
                      className={`main-image-container ${
                        showAssets === "image" ? "visible" : "invisible"
                      }`}
                    >
                      <img src={data.ImageUrl} alt="main-image" />
                    </div>
                    <video
                      autoPlay
                      muted
                      loop
                      id="baby-video"
                      className={
                        showAssets === "video" ? "visible" : "invisible"
                      }
                    >
                      <source src={data.VideoUrl} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <h2 className="baby-name">
                  {data.Name} #{data.metadata.symbol}
                </h2>
                <div className="baby-price">{data.price} SOL</div>
                <div className="tab-container">
                  <div className="tab-header">
                    <div className="tab active">Info</div>
                    <button
                      className="tab btnTransparent"
                      onClick={() =>
                        window.open(
                          `https://explorer.solana.com/address/${data.Address}`,
                          "_blank"
                        )
                      }
                    >
                      Token Details
                    </button>
                  </div>
                  <div className="tab-body">
                    <h4 className="attribute-title">
                      {data.Attributes.length} Attributes
                    </h4>
                    <div className="attributes-container row">
                      {data.Attributes.map((item, index) => (
                        <div className="col-md-6 col-lg-4" key={index}>
                          <div className="content">
                            <span>{item.trait_type}</span>
                            <p className="m-0">{item.value}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="greenBtn"
                    onClick={async () => {
                      if (state.userWallet && state.userWallet.string) {
                        if (data.status === "OnSalle") {
                          getUSDConvertion();
                        }
                      } else {
                        connectWallet();
                      }
                    }}
                  >
                    {data.status === "OnSalle"
                      ? "Buy with credit card"
                      : "Sold"}
                  </button>
                  <button
                    style={{ marginTop: 10 }}
                    className="greenBtn"
                    onClick={() => (data.status === "OnSalle" ? buy() : null)}
                  >
                    {data.status === "OnSalle" ? "Buy with SOL" : "Sold"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showCard ? (
            <div className="position-fixed alert-container">
              <div
                className="toast-container"
                style={{ width: 500, height: 640 }}
              >
                <div className="toast-content" style={{ width: "100%" }}>
                  <form name="tokenform" id="tokenform">
                    <iframe
                      // https://qorgaminguat.securepayments.cardpointe.com/
                      src={`https://qorgaming.securepayments.cardpointe.com/pay?total=${price}&mini=1&cf_hidden_wallet=${state.userWallet.string}&cf_hidden_babyid=${id}&cf_hidden_NFTid=${data._id}`}
                      // frameborder="0"
                      style={{ width: "100%", height: 520 }}
                      title="qorgamin"
                    ></iframe>{" "}
                    <input type="hidden" name="mytoken" id="mytoken" />
                  </form>
                  <button
                    style={{ marginTop: 10 }}
                    className="greenBtn"
                    onClick={() => setShowCard(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default SingleBaby;
