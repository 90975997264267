const { LAMPORTS_PER_SOL } = require("@solana/web3.js");
const BN = require('bn.js');
const negative1 = new BN(-1);
function numberToString(arg) {
    if (typeof arg === 'string') {
        if (!arg.match(/^-?[0-9.]+$/)) {
            throw new Error('while converting number to string, invalid number value \'' + arg + '\', should be a number matching (^-?[0-9.]+).');
        }
        return arg;
    } else if (typeof arg === 'number') {
        return String(arg);
    } else if (typeof arg === 'object' && arg.toString && (arg.toTwos || arg.dividedToIntegerBy)) {
        if (arg.toPrecision) {
            return String(arg.toPrecision());
        } else {
            // eslint-disable-line
            return arg.toString(10);
        }
    }
    throw new Error('while converting number to string, invalid number value \'' + arg + '\' type ' + typeof arg + '.');
}
/**
 * Returns value of unit in Wei
 *
 * @method getValueOfUnit
 * @param {String} unit the unit to convert to, default ether
 * @returns {BigNumber} value of the unit (in Wei)
 * @throws error if the unit is not correct:w
 */
function getValueOfUnit(unitInput) {
    var unit = unitInput ? unitInput.toLowerCase() : 'ether';
    var unitValue = unitMap[unit]; // eslint-disable-line
    if (typeof unitValue !== 'string') {
        throw new Error('[ethjs-unit] the unit provided ' + unitInput + ' doesn\'t exists, please use the one of the following units ' + JSON.stringify(unitMap, null, 2));
    }
    return new BN(unitValue, 10);
}
var unitMap = {
    'qorbi': (10 ** 9).toString(),
    'sol': LAMPORTS_PER_SOL.toString(),
};
function toWei(etherInput, unit) {
    let ether = numberToString(etherInput); // eslint-disable-line
    const base = getValueOfUnit(unit);
    const baseLength = unitMap[unit].length - 1 || 1;
    // Is it negative?
    const negative = ether.substring(0, 1) === '-'; // eslint-disable-line
    if (negative) {
        ether = ether.substring(1);
    }
    if (ether === '.') {
        throw new Error('[ethjs-unit] while converting number ' + etherInput + ' to wei, invalid value');
    }
    // Split it into a whole and fractional part
    var comps = ether.split('.'); // eslint-disable-line
    if (comps.length > 2) {
        throw new Error('[ethjs-unit] while converting number ' + etherInput + ' to wei,  too many decimal points');
    }
    var whole = comps[0],
        fraction = comps[1]; // eslint-disable-line
    if (!whole) {
        whole = '0';
    }
    if (!fraction) {
        fraction = '0';
    }
    if (fraction.length > baseLength) {
        throw new Error('[ethjs-unit] while converting number ' + etherInput + ' to wei, too many decimal places');
    }
    while (fraction.length < baseLength) {
        fraction += '0';
    }
    whole = new BN(whole);
    fraction = new BN(fraction);
    var wei = whole.mul(base).add(fraction); // eslint-disable-line
    if (negative) {
        wei = wei.mul(negative1);
    }
    return new BN(wei.toString(10), 10);
}
const getUnitValue = function (unit) {
    unit = unit ? unit.toLowerCase() : 'ether';
    if (!unitMap[unit]) {
        throw new Error('This unit "' + unit + '" doesn\'t exist, please use the one of the following units' + JSON.stringify(unitMap, null, 2));
    }
    return unit;
};
export const amountToLamports = function (number, unit) {
    unit = getUnitValue(unit);
    if (!BN.isBN(number) && !(typeof number === 'string')) {
        throw new Error('Please pass numbers as strings or BN objects to avoid precision errors.');
    }
    return BN.isBN(number) ? toWei(number, unit) : toWei(number, unit).toString(10);
};
// const result = amountToLamports('10.36', 'sol');