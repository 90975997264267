import React from "react";

const InputField = ({ onChange, placeholder }) => {
  return (
    <div className="field" style={{ background: "#262626" }}>
      <input
        type="text"
        onChange={({ target }) => onChange(target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
